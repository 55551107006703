import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import LazyLoad from 'react-lazyload';
import RenderConfigFE from '../../../src/components/render-config-fe';

import '../css/presentation.css';

const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Presentation" />

            <LazyLoad height={400} offset={100}>
                <section className="section section-widget">
                    <div className="container-fluid">
                        <RenderConfigFE className="title" name="PresentationTitle" type="html" />
                        <div className='row'>
                            <div className='col-sm-12 col-md-12 col-lg-12'>
                                <div style={{ height: '500px' }}></div>
                            </div>
                        </div>
                    </div>
                </section>
            </LazyLoad>
        </Layout>
    );
};

export default IndexPage;
